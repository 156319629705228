import store from "@/core/services/store";
import i18nService from "@/core/services/i18n.service.js";

const loadMenuChildren = function (id) {
  return new Promise((resolve, reject) => {
    this.$api.kb_menu
      .loadChildrenOptions({ id })
      .then((response) => {
        const sectionsList = [];
        const articlesList = [];
        response.data.forEach((cat) => {
          if (cat.entity === "section")
            sectionsList.push(createSectionOption(cat));
          else if (cat.entity === "article")
            articlesList.push(createArticleOption(cat));
        });
        const result = sectionsList.concat(articlesList);
        resolve(result);
      })
      .catch(() => {
        resolve(null);
      });
  });
};

const loadAncestorsTree = function (entity_id, entity_type) {
  return new Promise((resolve, reject) => {
    if (itemExistsOnMenu.call(this, entity_id, entity_type)) {
      this.$store.commit("menu/CLOSE_ALL");
      resolve();
    } else {
      this.$api.kb_menu
        .buildAncestorsTree(entity_id, entity_type)
        .then((response) => {
          let base_route = "aside.sections";
          response.data.forEach((level) => {
            if (level[0].parent_id) base_route += ".sec" + level[0].parent_id;
            const sectionsList = [];
            const articlesList = [];
            level.forEach((cat) => {
              if (cat.entity === "section") {
                this.$store.commit("structure/SET_SECTION_DATA", { data: cat, full: false });
                sectionsList.push(createSectionOption(cat));
              } else if (cat.entity === "article") {
                articlesList.push(createArticleOption(cat));
              }
            });
            const subList = sectionsList.concat(articlesList);
            const payload = {
              subMenu: subList,
              code: base_route,
            };
            this.$store.dispatch("menu/addSubmenu", payload);
          });
          resolve();
        })
        .catch(() => {
          reject(new Error());
        });
    }
  });
};

const itemExistsOnMenu = function (entity_id, entity_type) {
  const loadedOptions = Object.keys(this.$store.getters["menu/list"]);
  const prefix = entity_type === "section" ? "sec" : "art";
  const itemLastCode = prefix + entity_id;
  let flag = false;
  loadedOptions.forEach((category) => {
    const arr = category.split(".");
    if (arr[arr.length - 1] === itemLastCode) flag = true;
  });
  return flag;
};

const createArticleOption = (article) => {
  return {
    type: "link",
    to: {
      name: "article.view",
      params: { id: article.id },
    },
    icon: "flaticon2-sheet", // not required
    active: true,
    classes: function () {
      const arrayOfClasses = [];
      if (!article.is_read) {
        arrayOfClasses.push("unread-article");
      }
      return arrayOfClasses;
    },
    text: {
      default: article.name,
    },
    submenu: [],
    code: "art" + article.id,
    loaded: true,
  };
};

const createSectionOption = (section) => {
  return {
    type: "link",
    to: {
      name: "section.view",
      params: { id: section.id },
    },
    icon: "flaticon2-files-and-folders", // not required
    active: true,
    text: {
      default: section.name,
    },
    submenu: [],
    loader: function () {
      return loadMenuChildren.call(this, section.id);
    },
    code: "sec" + section.id,
    loaded: !section.has_children,
  };
};
export { createSectionOption, loadAncestorsTree };
export default {
  // Root level also must be configured as menu item (must have submenu key, other ignored)
  submenu: {
    // Link menu item

    newArticles: {
      router_link: "articles.new",
      icon: "flaticon-file-1",
      text_code: "new_articles.title",
      text: {
        code: "new_articles.title",
        default: "Unread articles",
      },
    },
    favorites: {
      router_link: "favorites",
      icon: "flaticon-star",
      active: true,
      text: {
        code: "favorites.title",
        default: "Favorites",
      },
    },
    familiarize: {
      router_link: "articles.familiarize",
      icon: "flaticon-warning-sign",
      text_code: "Familiarize",
      text: {
        code: "familiarize",
        default: "Familiarize",
      },
    },
    sections: {
      type: "link", // not required, not recommended (by default marks as link)
      to: {
        // not required, recommended (vue route-link to parameter)
        name: "section.index",
      },
      loader: function () {
        return loadMenuChildren.call(this, 0);
      },
      loaded: false,
      icon: "flaticon2-files-and-folders", // not required
      text: {
        code: "Разделы",
        default: "Разделы",
      },
      submenu: [],
    },
    snippets: {
      router_link: "snippets",
      icon: "flaticon-interface-2",
      text_code: "Сниппеты",
      active: function () {
        const user = store.getters.currentUser;
        return user
            ? user.is_admin || user.role_list.includes("editor")
            : false;
      },
      text: {
        code: "Сниппеты",
        default: "Snippets",
      },
    },
    drafts: {
      router_link: "drafts",
      icon: "flaticon-interface-4",
      text_code: "drafts.title",
      active: function () {
        const user = store.getters.currentUser;
        return user
            ? user.is_admin || user.role_list.includes("editor")
            : false;
      },
      text: {
        code: "drafts.title",
        default: "Drafts",
      },
    },
    archive: {
      router_link: "archive",
      icon: "flaticon-interface-3",
      text_code: "archive.title",
      active: function () {
        const user = store.getters.currentUser;
        return user
          ? user.is_admin || user.role_list.includes("editor")
          : false;
      },
      text: {
        code: "archive.title",
        default: "Archive",
      },
    },
    trash: {
      router_link: "trash",
      b_icon: "trash",
      active: function () {
        const user = store.getters.currentUser;
        return user
            ? user.is_admin || user.role_list.includes("editor")
            : false;
      },
      text: {
        code: "trash.title",
        default: "Trash",
      },
    },
    analytics: {
      router_link: "analytics",
      icon: "flaticon-analytics",
      text: {
        code: "analytics.header",
        default: "Analytics"
      }
    },
    help: {
      router_link: "help",
      icon: "flaticon-network",
      text: {
        code: "help.header",
        default: 'Help',
      },
      active: function () {
        return i18nService.getActiveLanguage() === 'ru';
      },
    },
    support: {
      router_link: "support",
      icon: "flaticon-questions-circular-button",
      text: {
        code: "support.header",
        default: "Support",
      },
    },
    wizard: {
      router_link: "wizard.section.view",
      b_icon: "play-btn",
      text: {
        code: "wizard.script.list.Wizard",
        default: "Wizard"
      }
    },
  },
};
