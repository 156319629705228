import Vue from "vue";

const wizard_sections = {
    namespaced: true,
    state: {
        loaded: false,
        sectionsList: {
            0: {
                id: 0,
                children: {},
                surveys: {},
            },
        },
        children: {},
        parents: {},
        surveys: {},
    },
    getters: {
        loading: state => state.sections.loading,
        loaded: state => state.loaded,
        section: state => id => state.sectionsList[id],
        sections: state => state.sectionsList,
        parents: state => state.parents,
        children: state => id => {
            return Object.values(state.sectionsList).filter(
                s => (s.parent_id || 0) === parseInt(id) && s.id !== 0
            );
        },
        surveys: (state, getters, rootState, rootGetters) => {
            return rootGetters['wizard_surveys/surveys'];
        },
    },
    mutations: {
        setParents(state, { id }) {
            let children = [parseInt(id)];
            let parents = {};
            Object.values(state.sectionsList)
                .forEach(s => {
                    if (children.includes(s.parent_id) || s.id === id)
                        children.push(s.id);
                    else
                        parents[s.id] = s;
                });

            Vue.set(state, 'parents', parents);
        },
        setChildren(state, { id }) {
            let children = Object.values(state.sectionsList).filter(s => (s.parent_id || 0) === parseInt(id) && s.id !== 0);
            Vue.set(state, 'children', children);
        },
        setSectionsList(state, sectionsList) {
            Vue.set(state, 'sectionsList', sectionsList);
            Vue.set(state, 'loaded', true);
        },
        setSection(state, { section }) {
            Vue.set(state.sectionsList, section.id, section);
        },
        deleteSection(state, { id }) {
            Vue.delete(state.sectionsList, id);
            
            /* Delete section in state.children */
            const newChildren = state.children.filter(item => Number(item.id) !== Number(id));
            Vue.set(state, 'children', newChildren);
            
            /* Delete section in state.parents */
            Vue.delete(state.parents, id);
        },
    },
    actions: {
        initSection({ commit }, section) {
            commit('setSection', { section });
            commit('setChildren', { id: section.id });
            commit('setParents', { id: section.id });
        },
        loadSections({ commit }) {
            return new Promise((resolve, reject) => {
                this.$app.$api.wizard.sections.getRoot()
                    .then(response => {
                        let sections = { 0: { id: 0 } };
                        // typeof response.data === 'object' &&
                        Object.keys(response.data.data).forEach(s => {
                            let section = response.data.data[s];
                            section.full = false;
                            sections[section.id] = section;
                        });
                        commit('setSectionsList', sections);
                        resolve({ loaded: true });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        loadSection({ getters, dispatch }, { id }) {
            return new Promise((resolve, reject) => {
                if (id === 0 || getters.section(id)) {
                    dispatch('initSection', getters.section(id));
                    resolve({ loaded: true });
                } else {
                    this.$app.$api.wizard.sections.get(id)
                        .then(response => {
                            dispatch('initSection', response.data.data);
                            resolve({ loaded: true });
                        })
                        .catch(error => {
                            reject(error);
                        });
                }
            });
        },
        saveSection({ dispatch }, { section }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('name', section.name);

                if(section.id) {
                    formData.append('id', section.id);
                    formData.append('_method', 'PUT');
                }

                formData.append('content', section.content);
                if (section.picture) {
                    formData.append('image', section.picture);
                }
                if(section.parent_id) {
                    formData.append('parent_id', section.parent_id);
                } else {
                    formData.append('parent_id', null);
                }
                this.$app.$api.wizard.sections.save(formData)
                    .then(response => {
                        dispatch('initSection', response.data.data);
                        resolve({ id: response.data.id });
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        deleteSection({ state, commit, getters }, { sectionId }) {
            return new Promise((resolve, reject) => {
                if (state.sectionsList[sectionId]) {
                    this.$app.$api.wizard.sections.delete(sectionId)
                        .then(res => {
                            if (res.data.deleted) {
                                if (getters.children) {
                                    let children = [sectionId];
                                    let allChildren = Object.values(state.sectionsList)
                                        .filter(s => {
                                            if (children.includes(s.parent_id) || children.includes(s.id)) {
                                                children.push(s.id);
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        });
                                    Object.values(getters.surveys).forEach(survey => {
                                        if (children.includes(survey.section_id))
                                            commit('wizard_surveys/deleteSurvey', { surveyId: survey.id }, { root: true });
                                    });
                                    allChildren.forEach(child => {
                                        commit('deleteSection', { id: child.id });
                                    });
                                }
                                commit('deleteSection', { sectionId });
                            }
                            resolve(res.data.deleted);
                        })
                        .catch(error => {
                            if (error.response)
                                reject(new Error('status' + error.response.status));

                            reject(new Error());
                        });
                } else {
                    resolve({ deleted: true });
                }
            });
        },
    },
};

export default wizard_sections;
