export default {
  toasts: {
    article: {
      view: {
        error: "Ошибка",
        error_access: "У вас нет доступа"
      }
    }
  },
  departments: {
    tree: {
      clear_all_text: "Очистить всё",
      clear_value_text: "Очистить значение",
      loading_text: "Загрузка...",
      no_children_departments: "Нет подчинённых отделов",
      no_results: "Нет результатов",
      retry_text: "Повторить?",
      search_prompt: "Начните вводить текст...",
    },
  },
  article: {
    edit: {
      data_type: {
        table: "Taблица",
        content: "Статья",
      },
      statuses: {
        public: "Опубликовано",
        gray: "Черновик",
        archive: "Архив",
      },
      types: {
        normal: "Статья",
        snippet: {
          informer: "Информер",
          content: "Контент",
        },
      },
      field: {
        name: "Название статьи",
        content: "Содержимое статьи",
        preview: "Краткое описание",
        section: "Родительский раздел",
        data_type: "Формат данных",
        table: "Excel Таблица",
        file: "Выберите файл",
        browse: "Поиск",
        type: "Тип",
        status: "Статус",
        responsible: "Ответственные",
      },
      title: {
        tags: {
          article: "Теги",
          snippet: {
            informer: "Типы информера",
            content: "Типы контента",
          },
        },
      },
    },
    title: {
      create: {
        article: "Создать статью",
        snippet: {
          informer: "Создать информер",
          content: "Создать контент",
        },
      },
      edit: {
        article: "Изменить статью",
        snippet: {
          informer: "Изменить информер",
          content: "Изменить контент",
        },
      },
      favorite_articles: "Избранные статьи",
      last_update_articles: "Последние изменённые",
    },
    date: {
      open: "Открыто включительно",
      close: "Закрыто включительно",
    },
    read: {
      new_head: "Новых",
      new_table: "Новая",
    },
    no_articles_categories: "В этом пространстве еще нет разделов и статей",
    view: {
      errors: {
        403: "У вас нет доступа"
      }
    }
  },
  snippet: {
    informer: {
      name: "Название информера",
      content: "Содержимое информера",
    },
    content: {
      name: "Название контента",
      content: "Содержимое контента",
    },
    used: "Используется",
  },
  comment: {
    mention: "@ Отметить человека",
    suggestion: {
      label: "Предложить изменение к статье",
      result: {
        title: "Предложение отправлено!",
        message:
          "Ответственный будет оповещен о предложении по изменению данной статьи!",
        message_thanks: "Спасибо за комментарий!",
      },
    },
  },
  search: {
    articles: "Все результаты в статьях",
    contents: "Все результаты в контентах",
    informers: "Все результаты в информерах",
    sections: "Все результаты в разделах",
    media: "Все результаты в файлах",
    media_title: "Файлы",
    nothing_found: "Ничего не найдено",
    surveys: "Все результаты в скриптах",
    include_in_result: "Включить в результат:",
    by_sections: "По разделам",
    strict: {
      any_form: "В любой форме",
      as_request: "Точно как в запросе",
    },
    nothing_found_long: "По данному поисковому запросу ничего не найдено",
  },
  hihub_service: {
    admin: "Администрирование",
  },
  support: {
    header: "Задать вопрос администратору",
    select_recipients: "Настроить получателей",
    placeholder: "Начните вводить...",
    error_required: "Это поле обязательное",
    error_length: "Длина сообщения не должна превышать 1000 символов",
    add_users: "Добавить пользователей",
    error_count: "Необходимо выбрать минимум 1 получателя",
  },
  help: {
    header: "Помощь",
  },
  buttons: {
    ok: "OK",
    cancel: "Отменить",
    delete: "Удалить",
    edit: "Редактировать",
    actions: "Действия",
    back: "Назад",
    save: "Сохранить",
    confirm: "Подтвердить",
    continue: "Продолжить",
    add: "Добавить",
    close: "Закрыть",
  },
  workspace_users: {
    title: "Пользователи пространства",
    table_headers: {
      name: "Имя",
      last_name: "Фамилия",
      email: "email",
      role: "Роль",
    },





  },
  access: {
    modal: {
      title: {
        main: "Настройки доступа",
        add: "Добавление разрешения\\запрета",
      },
      form: {
        tabs: {
          user: "Для пользователя",
          department: "Для подразделения",
          workspace: "Для роли",
        },
        fields: {
          scope: "Пространство",
          role: "Роль",
          permission: "Разрешение\\запрет",
          user: "Пользователь",
          department: "Подразделение",
        },
        descriptions: {
          scope:
            "Установленные права будут работать только в пределах выбранного пространства",
          department: "Установленные права будут работать только для выбранного отдела",
          forbidden: {
            false: "Разрешение на",
            true: "Запрет на",
          },
          recursive: {
            false: "без подотделов",
            true: "и подотделы",
          },
        },
      },
      header: {
        workspace: "Пространство",
        role: "Область",
        ability: "Разрешение",
        role_entity: "Группа",
        access: "Доступ",
      },
      workspace: {
        incorrect: "Некорректное пространство",
        denied: "Неизвестное пространство",
        root: "Общее пространство",
        unknown: "Пространство без имени",
        unknown_id: "[{id}] Пространство без имени",
      },
      department: {
        denied: "Неизвестное подразделение",
      },
      tooltip: {
        denied:
          "Разрешение\\запрет не может быть удалено текущим пользователем",
        forbidden: {
          false: "Разрешено",
          true: "Запрещено",
        },
      },
      buttons: {
        delete: "Удалить",
        cancel: "Отменить",
      },
      ability: {
        "*": "Полный доступ",
        read: "Просмотр",
        write: "Редактирование",
        full: "Полный доступ",
        denied: "Нет доступа",
      },
      role: {
        role: "Роль",
        by_type: {
          workspace: "в пространстве",
          user: "",
          department: "в отделе",
          departments: "в отделе и подотделах",
        },
      },
      status: {
        global: "Глобально",
        external: "",
        define: "К добавлению",
        forbid: "К удалению",
        active: "",
      },
    },
    roles: {
      admin: "Администратор",
      editor: "Редактор",
      members: "Пользователи",
      employees: "Сотрудники",
      user: "Пользователь",
      self: "Пользователь",
      creator: "Создатель",
      not_specified: "Не указано",
    },
  },
  delete_modal: {
    delete_article: "Удаление статьи",
    delete_section: "Удаление раздела",
    delete_article_question: "Вы уверены что хотите удалить статью",
    delete_section_question: "Вы уверены что хотите удалить раздел",
    delete_article_content:
      "Содержимое статьи, включая изображения и файлы, будут утеряны безвозвратно!",
    delete_section_content:
      "Содержимое раздела, включая изображения и файлы, будут утеряны безвозвратно!",
  },
  trash: {
    title: "Корзина",
    alert: "Статьи и разделы хранятся в корзине 30 дней, затем удаляются безвозвратно",
    articles: "Статьи",
    sections: "Разделы",
    headers: {
      deleted_at: "Дата удаления",
      title: "Название"
    },
    restore_modal: {
      title: "Восстановить",
      body_articles: "Восстановить выбранные статьи?",
      body_sections: "Восстановить выбранные разделы? Статьи данных разделов также будут восстановлены",
    }
  },

  "Таблица обрабатывается, перезагрузите страницу позже":
    "Таблица обрабатывается, перезагрузите страницу позже",
  "Не удалось обработать таблицу": "Не удалось обработать таблицу",
  "Тип файла не соответствует разрешенным к загрузке":
    "Тип файла не соответствует разрешенным к загрузке",
  "Максимальный размер файла": "Максимальный размер файла",
  "Необходимо предоставить название": "Необходимо предоставить название",
  "Переместить в раздел": "Переместить в раздел",
  "Убрать из избранного": "Убрать из избранного",
  "Название должно содержать минимум 3 символа":
    "Название должно содержать минимум 3 символа",
  "Содержимое статьи не может быть пустым":
    "Содержание статьи не может быть пустым",
  "Права применены на все подчинённые отделы":
    "Права применены на все подчинённые отделы",
  "Права не применены на все подчинённые отделы":
    "Права не применены на все подчинённые отделы",
  "Применить на все подчинённые демаптаменты":
    "Применить на все подчинённые демаптаменты",
  Подотделы: "Подотделы",
  "Один из списка": "Один из списка",
  "Раскрывающийся список": "Раскрывающийся список",
  "Несколько из списка": "Несколько из списка",
  "Отдел...": "Отдел...",
  "Применить к подотделам": "Применить к подотделам",
  "Добавить информер": "Добавить информер",
  "Добавить контент": "Добавить контент",
  Сниппеты: "Сниппеты",
  "К следущему вопросу": "К следущему вопросу",
  "Перейти к ответу": "Перейти к ответу",
  Информеры: "Информеры",
  "Поиск по пользователям": "Поиск по пользователям",
  "Что будет удалено:": "Что будет удалено:",
  Контенты: "Контенты",
  "База знаний": "База знаний",
  Разделы: "Разделы",
  Раздел: "Раздел",
  "Поиск по базе": "Поиск по базе",
  Поиск: "Поиск",
  Навигатор: "Навигатор",
  Статьи: "Статьи",
  Скрипты: "Скрипты",
  "IT-внедрения": "IT-внедрения",
  Разработка: "Разработка",
  "Доступен только мне": "Доступен только мне",
  "Укажите ключ": "Укажите ключ",
  "Ключ раздела": "Ключ раздела",
  "Выберите создателя": "Выберите создателя",
  "Ответственный за статью сотрудник отвечает за предложения по изменению статьи":
    "Ответственный за статью сотрудник отвечает за предложения по изменению статьи",
  "Создать раздел": "Создать раздел",
  "Создание раздела": "Создание раздела",
  "Редактирование раздела": "Редактирование раздела",
  "Редактировать раздел": "Редактировать раздел",
  Заголовок: "Заголовок",
  "Введите заголовок": "Введите заголовок",
  "Введите текст здесь": "Введите текст здесь",
  "Нет подходящих пользователей": "Нет подходящих пользователей",
  Опубликовать: "Опубликовать",
  Отменить: "Отменить",
  Название: "Название",
  Описание: "Описание",
  "Ссылка скопирована": "Ссылка скопирована",
  Поделиться: "Поделиться",
  "Выберите категорию раздела. Можно несколько":
    "Выберите категорию раздела. Можно несколько",
  "Настройки доступа": "Настройки доступа",
  "Настроить права доступа на редактирование":
    "Настроить права доступа на редактирование",
  "Назначие прав пользователя": "Назначение прав пользователя",
  "Назначие прав депратаментов": "Назначение прав департаментов",
  "Выберите пользователя": "Выберите пользователя",
  "Выберите группы пользователей": "Выберите группы пользователей",
  Расширенный: "Расширенный",
  "Поисковая выдача": "Поисковая выдача",
  "Восстановить эту версию": "Восстановить эту версию",
  "Добавить в избранное": "Добавить в избранное",
  "В избранное": "В избранное",
  "Удалить из избранного": "Удалить из избранного",
  "Выберите раздел": "Выберите раздел",
  Переместить: "Переместить",
  "Результаты поиска": "Результаты поиска",
  "Написать комментарий": "Написать комментарий",
  "Введите сообщение": "Введите сообщение",
  "Скрыть форму": "Скрыть форму",
  Ответить: "Ответить",
  Роль: "Роль",
  "Управление пользователями": "Управление пользователями",
  "Пригласить пользователей": "Пригласить пользователей",

  "Это поле обязательное": "Это поле обязательное",
  "Выберите родительский раздел": "Выберите родительский раздел",
  "Вернуться к редактированию": "Вернуться к редактированию",
  "подсказка по созданию статьи":
    'Чтобы создать статью в разделе, нажмите кнопку "Создать статью" в нижней части левого сайдбара',
  "подсказка по созданию раздела":
    'Чтобы создать раздел, нажмите кнопку "Создать раздел", справа, в верхней части экрана',
  "Изменения вносил": "Изменения вносил",
  "Раздел не содержит подразделов и статей":
    "Раздел не содержит подразделов и статей",
  "Предыдущие версии статьи не найдены": "Предыдущие версии статьи не найдены",
  Страниц: "Страниц",
  "Дата изменения": "Дата изменения",
  Отправить: "Отправить",
  "История версий": "История версий",
  "Ответственные за статью": "Ответственные за статью:",
  "Начните вводить...": "Начните вводить...",

  "Ошибка отображения списка доступов": "Ошибка отображения списка доступов",
  "Ошибка сохранения": "Ошибка сохранения",
  Ошибка: "Ошибка",
  "Недостаточно прав доступа или ресурс не существует":
    "Недостаточно прав доступа или ресурс не существует",
  "Введите текст здесь...": "Введите текст здесь...",
  Код: "Код",
  Зеленый: "Зеленый",
  Жирный: "Жирный",
  "С пробелами": "С пробелами",
  Обрамлённый: "Обрамлённый",
  Заглавный: "Заглавный",
  Серый: "Серый",
  Прозрачный: "Прозрачный",
  Подсвеченный: "Подсвеченный",
  "Верхний уровень": "Верхний уровень",
  "Убрать лайк": "Убрать лайк",
  Нравится: "Нравится",
  Тег: "Тег",
  "Тег не содержит привязанный статей": "Тег не содержит привязанный статей",
  Теги: "Теги",
  "Права доступа": "Права доступа",
  "Пользователь...": "Пользователь...",
  "Право доступа...": "Право доступа...",
  "Создать подраздел": "Создать подраздел",
  "Закрыть доступ всем": "Закрыть доступ всем",
  "По умолчанию": "По умолчанию",
  "Полный доступ": "Полный доступ",
  "Нет доступа": "Нет доступа",
  Просмотр: "Просмотр",
  Редактирование: "Редактирование",
  "Импорт из БЗ V16": "Импорт из БЗ V16",
  "Импорт из БЗ V16 кнопка": "Запустить",
  "Импорт из БЗ V16 текст":
    " импорт статей и разделов из 16 версии Базы Знаний.",
  "Импорт из БЗ V16 текст 2":
    "ВНИМАНИЕ! Если что-то пошло не так, то многократный запуск синхронизации не поможет решить проблему...",
  "Импорт из БЗ V16 запущен":
    "Импорт запущен - это может занять какое-то время...",

  "У вас нет доступа на добавление раздела":
    "У вас нет доступа на добавление раздела",
  "Поделиться в Б24": "Поделиться в Б24",
  "Статья будет опубликована в Живой ленте":
    "Статья будет опубликована в Живой ленте.",
  "Спасибо что поделились статьей": "Спасибо, что поделились статьей!",
  "Ресурс не найден": "Ресурс не найден",
  "Добавить статью": "Добавить статью",
  "Добавить раздел": "Добавить раздел",
  "Удалить выбранные": "Удалить выбранные",
  Пользователи: "Пользователи",
  Журналирование: "Журналирование",
  Инструменты: "Инструменты",
  "Панель администратора": "Панель администратора",
  "Название опроса": "Название опроса",
  "Описание опроса": "Описание опроса",
  "Создать опрос": "Создать опрос",
  "Создание опроса": "Создание опроса",
  "Введите описание": "Введите описание",
  "Введите название": "Введите название",
  "Редактировать вопросы": "Редактировать вопросы",
  Вперёд: "Вперёд",
  "Создание вопросов": "Создание вопросов",
  Вопрос: "Вопрос",
  "Введите формулировку": "Введите формулировку",
  "Вариант ответа": "Вариант ответа",
  "Формулировка варианта": "Формулировка варианта",
  "Показать ответ": "Показать ответ",
  "К следующему вопросу": "К следующему вопросу",
  "Новый вопрос": "Новый вопрос",
  "Сохранить вопросы": "Сохранить вопросы",
  "Формулировка вопроса": "Формулировка вопроса",
  "Заполните все поля": "Заполните все поля",
  "Изменение вопросов для: ": "Изменение вопросов для: ",
  "Редактирование опроса": "Редактирование опроса",
  subscribe: "Подписаться на обновления",
  unsubscribe: "Отписаться от обновлений",
  email: "email",
  start_typing_title: "Начните вводить название",
  no_matching_tags: "Не найдено подходящих тегов",
  sorting: "Сортировка",
  sorting_description: "Чем больше значение, тем выше приоритет",
  sorting_range: "От 1 до 100",
  sorting_validation:
    "Сортировка должно быть установлена в диапазоне значений от 1 до 100",
  enter_article_name: "Введите название статьи",
  additionally: "Дополнительно",
  active_time: "Срок активности статьи",
  from: "от",
  to: "до",
  updated: "Изменено",
  to_article: "Перейти к статье",
  to_section: "Перейти к разделу",
  full_search: "Расширенный поиск",
  workspace: {
    workspaces: "Рабочие пространства",
    create_new: "Создать новое рабочее пространство",
    title: "Название",
    description: "Краткое описание",
    create: "Создать",
    id: "Идентификатор",
    manage: "Управление рабочими пространствами",
    delete: "Удаление рабочих пространств",
    del_warning:
      "Вы уверены? Все статьи, находящиеся в удаляемых рабочих пространствах, станут недоступны",
    edit: "Редактировать рабочее пространство",
    users: "Пользователи",
    top_title: "Ваши рабочие пространства",
    public_title: "Общая База Знаний",
    public_description: "Статьи в общем доступе",
    workspace_attach: "Привязать к рабочему пространству",
    workspace_detach: "Отвязать от рабочего пространства",
    tariff_restriction:
      "Ваш тариф не позволяет создать больше рабочих пространств",
    show_tariffs: "Тарифы",
  },

  familiarization_title: "Ознакомление со статьёй",
  familiarization_description: "Необходимо ознакомиться со статьёй Базы Знаний",
  link_to_article: "Ссылка на статью",
  i_familiarize: "Я ознакомлен(а)!",
  familiarized: "Прочитано",
  undo: "Отменить",
  generation_started: "Генерация запущена",
  restart: "Повторный запуск",
  need_to_familiarize: "Обязательная к прочтению",
  familiarize_required: "Обязательность прочтения",
  familiarize: "Ознакомиться",
  clear_changes: "Очистить изменения",
  accept_selected: "Подтвердите выбор",

  department: "Департамент",
  position: "Должность",
  competence: "Компетенция",
  employe: "Сотрудник",
  add_familization_users: "Добавить участников",
  select_all: "Выбрать все",
  familization_users: "Сотрудники в назначении",
  familization_search_input: "Введите название назначения",
  user_search_input: "Введите ФИО сотрудника",
  departments_search_input: "Введите название должности",

  select_users: "Выберите сотрудников",
  departments_title: "Департаменты",
  departments_modal_title: "Отделы",
  positions: "Должности",
  competences: "Компетенции",
  employees: "Сотрудники",
  employees_in_familization: "Сотрудники",
  save_changes: "Сохранить изменения",
  department_search: "Поиск по департаменту",
  position_search: "Поиск по должности",
  competence_search: "Поиск по компетенции",
  employee_search: "Поиск по сотруднику",
  metrics: {
    settings: "Настройки метрики",
    activity: "Активность",
    activity_description: "Настройка включения логирования метрик",
    threshold: "Пороговое значение",
    threshold_description:
      "Минимальное время загрузки страницы в секундах, которое должно журналироваться",
    threshold_validate_range: "Значение должно быть от 0 до 50",
  },
  main: "Главная",
  new_articles: {
    title: "Новые статьи",
    remove: "Убрать все из новых",
    remove_single: "Убрать из новых",
    remove_confirm: "Вы уверены, что хотите убрать выделенные статьи из новых?",
  },
  familiarize_page: {
    title: "Список обязательных для ознакомления статей",
    is_empty: "Список обязательных для ознакомления статей пуст",
  },
  favorites: {
    title: "Избранное",
    is_empty: "Список избранного пуст",
  },
  archive: {
    title: "Архив",
    is_empty: "Архив пуст",
  },
  drafts: {
    title: "Черновики",
    is_empty: "Список черновиков пуст",
  },
  share_url: {
    copy: "Скопировать",
    copied: {
      title: "Скопировано",
      text: "Ссылка скопирована в буфер обмена",
    },
    share_b24: "Опубликовать в Б24",
    hihub_url: "Прямая ссылка",
    bitrix_url: "Поделиться в Битриксе",
  },
  section_view: "Просмотр раздела",
  article_view: "Просмотр статьи",
  "snippet.content_view": "Просмотр контента",
  "snippet.informer_view": "Просмотр информера",
  documents: "Документы",
  more: "Ещё",
  preview: "Предпросмотр",
  subsections: "Подразделы",
  root_section_description:
    "Список корневых разделов и статей без привязки к разделу",
  section_move_warning: "Данный раздел отмечен вами для перемещения",
  enter_the_query: "Введите запрос",
  popular_articles: "Популярные статьи",
  last_seen_articles: "Последние просмотренные статьи",
  journal: "Журналирование",
  analytics:{
    header: "Аналитика"
  },
  analyticsPage: {
    popularAuthors: {
      title: "Самые публикуемые авторы"
    },
    articlesPerPeriod: {
      number: "Создано статей",
      title: "Количество созданных статей",
      week_number: "Номер недели",
      month_number: "Месяц",
      year_number: "Год"
    },
    most_viewed_articles: {
      title: "Самые просматриваемые статьи",
      denied_article: "Нет доступа к этой статье"
    },
    authorizations_per_period: {
      number: "Количество авторизаций",
      title: "Количество посетителей Базы Знаний",
    }
  },
  manageUsers: {

    changed_pass_success: "Пароли изменены без отправки уведомлений",
    del_fail: "Произошла ошибка при удалении пользователей",
    del_success: "Удаление пользователей прошло успешно",
    del_warning: "Вы уверены, что хотите удалить выделенных пользователей?",
    departments: "Управление отделами",
    departments_null: "Вы пока не добавили ни одного отдела",
    departments_show: "Организационная структура предприятия",
    departments_show_head: "Руководитель",
    departments_show_title: "Наименование",
    notifications: "Уведомления",
    notifications_manual: "Ручная рассылка сообщений",
    notifications_password: "Генерация пароля",
    notifications_start: "Уведомление о старте оценки",
    notifications_success: "Уведомления были отправлены",
    notifications_text: "Внимание! Если вы нажмёте на кнопку 'Генерация пароля', на почту пользователя будет отправлен новый сгенерированный пароль. После этого предыдущий пароль пользователя будет недействительным",
    no_notifications_password: "Новый пароль без уведомления",
    positions: "Управление должностями",

    delete: "Удалить из ХайХаба"
  },
  familiarization_page: {
    manage_list: "Управление списком",
    title: "Назначена на прочтение"
  },
  wizard: {
    "script": {
      "one_with_title": "Скрипт {title}",
      "one": "Скрипт",
      "list": {
        "Wizard": "Скрипты",
        "buttons": {
          "create": "Создать скрипт",
          "edit": "Редактировать скрипт",
          "delete": "Удалить скрипт",
          "create_section": "Добавить раздел скриптов"
        },
        "confirm_delete_survey": "Вы уверены, что хотите безвозвратно удалить данный скрипт?",
        no_categories: "В этом пространстве еще нет разделов скриптов",
      },
      "editor": {
        "create": {
          "heading": "Создание скрипта",
          "publish": "Опубликовать",
          "error_save": "Ошибка сохранения",
          "tariff_restriction": "Ваш тариф не позволяет создать больше скриптов",
          "show_tariffs": "Посмотреть тарифы"
        },
        "edit": {
          "heading": "Редактирование скрипта",
          "edit_questions": "Редактировать вопросы",
          "save": "Сохранить"
        },
        "title": "Название скрипта",
        "placeholder_title": "Введите название",
        "section": "Родительский раздел",
        "placeholder_section": "Начните вводить...",
        "description": "Описание скрипта",
        "placeholder_description": "Введите описание",
        "optional": "Опционально",
        "radio": "Один из списка",
        "checklist": "Несколько из списка",
        "listing": "Раскрывающийся список",
        "root_section": "Верхний уровень",
        "picture": "Картинка"
      }
    },
    "question": {
      "editor": {
        "create_heading": "Создание вопросов",
        "edit_heading": "Изменение вопросов для: ",
        "question": "Вопрос",
        "new_question": "Новый вопрос",
        "option": "Вариант ответа",
        "sorting": "Сортировка",
        "show_answer": "Показать ответ",
        "to_next_question": "К следующему вопросу",
        "link_to_article": "Ссылка на статью",
        "link_to_section": "Ссылка на раздел",
        "show_article": "Переход на статью",
        "show_section": "Переход на раздел",
        "add_option": "Добавить вариант ответа",
        "additional": "Дополнительные вопросы",
        "add_question": "Добавить вопрос",
        "delete_question": "Удалить вопрос",
        "save": "Сохранить вопросы",
        "error_fill_fields": "Заполните все поля",
        "placeholder_question": "Формулировка вопроса",
        "search": "Поиск по Базе знаний",
      },
      "view": {
        "back": "Назад",
        "forward": "Вперёд",
        "additional": "Дополнительные вопросы"
      }
    },
    "section": {
      "view": {
        "edit": "Редактировать",
        "create": "Создать подраздел",
        "title": "Разделы скриптов",
        "back": "Назад"
      },
      "editor": {
        "header_create": "Создание раздела скриптов",
        "header_edit": "Редактирование раздела скриптов",
        "title": "Название",
        "placeholder_title": "Введите название",
        "section": "Родительский раздел",
        "placeholder_section": "Начните вводить...",
        "description": "Описание",
        "placeholder_description": "Введите описание",
        "cancel": "Отменить",
        "save": "Сохранить",
        "create": "Создать раздел скриптов",
        "error_required": "Это поле обязательное",
        "error_title": "Название должно содержать минимум 3 символа",
        "root_section": "Верхний уровень"
      }
    },
  }
};
