import Vue from "vue";

const wizard_surveys = {
    namespaced: true,
    state: {
        surveys: {},
        surveysArray: {},
        fullSurveysLoaded: false,
    },
    getters: {
        fullSurveysLoaded: state => state.fullSurveysLoaded,
        surveys: state => state.surveysArray,
        surveyById: state => ({ surveyId }) => state.surveys ? state.surveys[surveyId] : undefined,
        questionsBySurveyId: state => ({ surveyId }) => {
            return state.surveys[surveyId] && state.surveys[surveyId].questions
                ? Object.values(state.surveys[surveyId].questions).filter(question => question.aggregator_id === surveyId)
                : undefined;
        }
    },
    mutations: {
        setFullSurveysLoaded(state, { loaded }){
            Vue.set(state, 'fullSurveysLoaded', loaded);
        },
        setSurvey(state, { surveyId, survey }) {
            if (state.surveys && Object.keys(state.surveys).length > 0) {
                Vue.set(state.surveys, surveyId, survey);
            } else {
                let surveys = {};
                surveys[surveyId] = survey;
                Vue.set(state, 'surveys', surveys);
            }
        },
        setSurveys(state, { surveys }) {
            let surveysObject = {};
            Object.values(surveys).forEach(survey => {
                surveysObject[survey.id] = survey;
            });
            if (state.surveys && Object.keys(state.surveys).length > 0) {
                Object.keys(surveysObject).forEach(surveyId => {
                    if (state.surveys[surveyId] === undefined) {
                        Vue.set(state.surveys, surveyId, surveys[surveyId]);
                    }
                });
            } else {
                Vue.set(state, 'surveys', surveysObject);
            }

            if (state.surveysArray && Object.keys(state.surveysArray).length > 0) {
                Object.keys(surveys).forEach(surveyId => {
                    if (state.surveysArray[surveyId] === undefined) {
                        Vue.set(state.surveysArray, surveyId, surveys[surveyId]);
                    }
                });
            } else {
                Vue.set(state, 'surveysArray', surveys);
            }
        },
        setQuestions(state, { surveyId, questions }) {
            let sorted = questions.sort((a, b) => {
                return a.id - b.id;
            });
            Vue.set(state.surveys[surveyId], 'questions', sorted);
        },
        createSurvey(state, { survey }) {
            Vue.set(state.surveys, survey.id, survey);
            state.surveysArray.push(survey);
        },
        updateSurvey(state, { surveyId, title, description, section_id }) {
            let survey = state.surveys[surveyId];
            survey.description = description;
            survey.title = title;
            survey.section_id = section_id;
            Vue.set(state.surveys, surveyId, survey);
        },
        deleteSurvey(state, surveyId) {
            let index = state.surveysArray.findIndex(s => s.id === surveyId);
            Vue.delete(state.surveys, surveyId);
            Vue.delete(state.surveysArray, index);
        },
        updateSorting(state, surveys) {
            surveys.forEach(s => {
                const stateSurvey = state.surveysArray.find(stateSurvey => stateSurvey.id === s.id);
                stateSurvey.sorting = s.sorting;
            });
        },
        sortSurveys(state) {
            state.surveysArray.sort((a, b) => {
                if(a.sorting < b.sorting) return -1;
                if(a.sorting > b.sorting) return 1;
                return 0;
            });
        },
    },
    actions: {
        loadSurveyById({ commit, getters }, { surveyId }) {
            return new Promise((resolve) => {
                if (getters.surveyById({ surveyId }) === undefined) {
                    this.$app.$api.wizard.surveys.getSurvey(surveyId)
                        .then(survey => {
                            commit('setSurvey', { surveyId, survey: survey.data.data });
                            resolve({ loaded: true });
                        });
                } else {
                    resolve({ loaded: true });
                }
            });
        },
        loadQuestionsBySurveyId({ commit, getters }, { surveyId }) {
            return new Promise(() => {
                if (getters.questionsBySurveyId({ surveyId }) === undefined) {
                    this.$app.$api.wizard.questions.getBySurveyId(surveyId)
                        .then(questions => {
                            commit('setQuestions', { surveyId, questions: questions.data });
                        });
                }
            });
        },
        loadSurveys({ commit, getters }) {
            return new Promise( resolve => {
                if (!getters.fullSurveysLoaded) {
                    this.$app.$api.wizard.surveys.getSurveys()
                        .then(surveys => {
                            let list = surveys.data;
                            commit('setSurveys', { surveys: list });
                            commit('setFullSurveysLoaded', { loaded: true });
                            resolve({ loaded: true });
                        });
                }
            });
        },
        createSurvey({ commit }, { title, picture, description, section_id }) {
            return new Promise((resolve, reject) => {
                let formData = new FormData();
                formData.append('title', title);
                formData.append('description', description);
                formData.append('section_id', section_id);
                if (picture) {
                    formData.append('image', picture);
                }
                this.$app.$api.wizard.surveys.addSurvey(formData)
                    .then(survey => {
                        commit('createSurvey', { survey: survey.data });
                        resolve(survey);
                    })
                    .catch(error => {
                        if (error.response && Number(error.response.status) === 422)
                            reject(error.response.data.errors);
                        else if (error.response && Number(error.response.status) === 402)
                            reject(error.response.data);
                        else reject(new Error());
                    });
            });
        },
        updateSurvey({ commit }, { title, picture, description, id, section_id }) {
            let formData = new FormData();
            formData.append('title', title);
            formData.append('description', description);
            formData.append('section_id', section_id);
            formData.append('_method', 'PUT');
            if (picture) {
                formData.append('image', picture);
            }
            this.$app.$api.wizard.surveys.updateSurvey(id, formData).then(() => {
                commit('updateSurvey', { surveyId: id, title, description, section_id });
            });
        },
        updateQuestions({ commit }, { surveyId, questions }) {
            return new Promise((resolve) => {
                return this.$app.$api.wizard.questions
                    .updateQuestions({ surveyId, questions })
                    .then(questions => {
                        commit('setQuestions', { surveyId, questions: questions.data });
                        resolve({ updated: true });
                    });
            });
        },
        saveQuestions({ commit }, { surveyId, questions }) {
            return new Promise((resolve) => {
                this.$app.$api.wizard.questions.createQuestions({ surveyId, questions })
                    .then(questions => {
                        commit('setQuestions', { surveyId, questions: questions.data });
                        resolve({ saved: true });
                    })
                    .catch(err => {
                        return err;
                    });
            });
        },
        deleteSurvey({ state, commit }, { surveyId }) {
            return new Promise((resolve, reject) => {
                if (state.surveys[surveyId]) {
                    this.$app.$api.wizard.surveys.deleteSurvey(surveyId)
                        .then(response => {
                            if (response.data.deleted) {
                                commit('deleteSurvey', surveyId);
                                resolve(response.data.deleted);
                            }
                        })
                        .catch(error => {
                            if (error.response)
                                reject(new Error('status ' + error.response.status));

                            reject(new Error());
                        });
                } else {
                    resolve({ deleted: true });
                }
            });
        },
        // eslint-disable-next-line no-unused-vars
        deleteQuestion({ state, commit }, { surveyId, questionId }) {
            this.$app.$api.wizard.questions.deleteQuestion({ questionId }).then(() => {
                // commit('deleteQuestion', { surveyId, questionId });
            });
        },
        updateSorting({ commit }, data) {
            return this.$app.$api.wizard.surveys.updateSorting({ surveys: data }).then(() => {
                commit('updateSorting', data);
                commit('sortSurveys');
            });
        }
    },
};

export default wizard_surveys;
